/* Import IMA plugin. */
import 'videojs-ima';
/* Import ads plugin. */
import 'videojs-contrib-ads';

import { trackers } from '@rmm/error-tracker';
import { is_dev } from '../util/env.js';
import { ERROR_TRACKER_RENDER_GROUP, canRunAds, dispatchCustomEvent } from '../util/helper';
import { adsManagerLoadedCallback } from './nielsen.js';

const { SimpleErrorTracker } = trackers;

const imaAdsManagerEvents = [
	{ key: 'STARTED', name: 'IMA Ads Manager: Ad started', differentiate: true },
	{ key: 'IMPRESSION', name: 'IMA Ads Manager: Impression URL pinged', differentiate: true },
	{ key: 'COMPLETE', name: 'IMA Ads Manager: Ad completed', differentiate: true },
	{ key: 'SKIPPED', name: 'IMA Ads Manager: Ad skipped', differentiate: true },
	{ key: 'LOADED', name: 'IMA Ads Manager: Ad loading complete' },
	{ key: 'AD_METADATA', name: 'IMA Ads Manager: Ads list loaded' },
];

export function gamAdtagRawUpdate(player, adTagUrl) {
	if (!adTagUrl) return;
	const isMute = player.muted();
	if (isMute) {
		adTagUrl = adTagUrl.replace('playbackmethod=1', 'playbackmethod=2');
		adTagUrl = adTagUrl.replace('vpmute=0', 'vpmute=1');
	} else {
		adTagUrl = adTagUrl.replace('playbackmethod=2', 'playbackmethod=1');
		adTagUrl = adTagUrl.replace('vpmute=1', 'vpmute=0');
	}
	return adTagUrl;
}

/* Player ad initialization routine with IMA plugin. */
export default function initialize_ads(
	player,
	ads = {},
	on_error,
	player_id,
	player_name,
	nSdkInstance,
) {

	if (!canRunAds()) {
		return;
	}

	const expectPrerollTimeout = ads?.expectPrerollTimeout * 1000 || 5000;
	const IMA_CONFIG = {
		adTagUrl: ads?.ad_tag_url,
		debug: is_dev(),
		contribAdsSettings: {
			timeout: expectPrerollTimeout,
			prerollTimeout: expectPrerollTimeout,
			postrollTimeout: expectPrerollTimeout,
		},
		ppid: ads?.ppid || '',
		requestMode: 'onPlay',
		vPaidMode: window.google?.ima?.ImaSdkSettings?.VpaidMode?.INSECURE,
		preventLateAdStart: true,
		disableCustomPlaybackForIOS10Plus: true,
		omidVendorAccess: {
			[window.google.ima.OmidVerificationVendor.MOAT]: window.google.ima.OmidAccessMode.FULL,
			[window.google.ima.OmidVerificationVendor.GOOGLE]: window.google.ima.OmidAccessMode.FULL,
			[window.google.ima.OmidVerificationVendor.OTHER]: window.google.ima.OmidAccessMode.FULL,
		},
		adsManagerLoadedCallback: nSdkInstance
			? () => adsManagerLoadedCallback(player, nSdkInstance)
			: undefined,
	};
	const INITIAL_PARAMS = {
		player,
		ads,
		on_error: (on_error || (() => { })).toString(),
		player_id,
		player_name,
	};
	const adInitTracker = new SimpleErrorTracker('Ads initialized', 100, {
		id: player_id,
		position: player_name,
		renderGroup: ERROR_TRACKER_RENDER_GROUP.adInit,
	});
	if (!window.google) {
		adInitTracker.sendError(
			{
				name: 'Google not defined in window',
				message: {
					initialParams: INITIAL_PARAMS,
					imaConfig: IMA_CONFIG,
				},
			},
			1,
		);
		return;
	}
	/* Initialize IMA on player. */
	try {
		player.ima(IMA_CONFIG);
		/* Update internal state. */
		player.safeupdate().update({
			ads: ads?.ad_tag_url,
		});
		adInitTracker.sendSuccess();
	} catch (err) {
		adInitTracker.sendError(
			{
				name: err.name,
				message: {
					initialParams: INITIAL_PARAMS,
					imaConfig: IMA_CONFIG,
					error: err,
				},
			},
			1,
		);
	}

	/**
	 * Trackers based on IMA events.
	 * See https://github.com/googleads/videojs-ima#ima-plugin-ad-events for reference
	 */
	player.on('ads-ad-started', () => {
		const adName = player.currentTime() > 4 ? 'Postroll' : 'Preroll';
		const adStartedTracker = new SimpleErrorTracker(`${adName} Started`, 100, {
			id: player_id,
			position: player_name,
			renderGroup: ERROR_TRACKER_RENDER_GROUP.adServe,
		});
		adStartedTracker.sendSuccess();
	});
	player.on('ads-manager', (e) => {
		const adManagerTracker = new SimpleErrorTracker('Ads IMA: Ads Manager', 100, {
			id: player_id,
			position: player_name,
			renderGroup: ERROR_TRACKER_RENDER_GROUP.adInit,
		});
		adManagerTracker.sendSuccess();
		if (canRunAds()) {
			const adsManager = e.adsManager;

			adsManager.addEventListener(window.google.ima.AdEvent.Type.VOLUME_CHANGED, (event) => {
				const vol = event?.ad?.data?.volume
				player.volume(vol);
				if (vol > 0) {
					player.muted(false)
				}
				dispatchCustomEvent(player, 'volumeChange');
			});

			imaAdsManagerEvents.forEach((imaEvent) => {
				adsManager.addEventListener(window.google.ima.AdEvent.Type[imaEvent.key], () => {
					const adName = player.currentTime() > 4 ? 'Postroll' : 'Preroll';
					const logName = imaEvent.differentiate ? `${imaEvent.name} (${adName})` : imaEvent.name;
					const adEventTracker = new SimpleErrorTracker(logName, 100, {
						id: player_id,
						position: player_name,
						renderGroup: ERROR_TRACKER_RENDER_GROUP[imaEvent.differentiate ? 'adServe' : 'adInit'],
					});
					adEventTracker.sendSuccess();
				});
			});
		}
	});
	player.on('ads-loader', () => {
		const adLoaderTracker = new SimpleErrorTracker('Ads IMA: Ads Loader', 100, {
			id: player_id,
			position: player_name,
			renderGroup: ERROR_TRACKER_RENDER_GROUP.adInit,
		});
		adLoaderTracker.sendSuccess();
	});
	player.on('ads-request', () => {
		const adRequestTracker = new SimpleErrorTracker('Ads IMA: Ads Request', 100, {
			id: player_id,
			position: player_name,
			renderGroup: ERROR_TRACKER_RENDER_GROUP.adInit,
		});
		adRequestTracker.sendSuccess();
	});
	player.on('ads-request', (a) => {
		console.log('*** Ads Request event:', a);
	});

	/**
	 * Tracker based on videojs-contrib-ads error event.
	 */
	player.on('adserror', (error) => {
		const adErrorTracker = new SimpleErrorTracker('Ad error', 1, {
			id: player_id,
			position: player_name,
			renderGroup: ERROR_TRACKER_RENDER_GROUP.adServe,
		});
		console.error('IMA_ADSERROR', error);
		adErrorTracker.sendError(
			{
				name: 'IMA Ads error',
				message: {
					initialParams: INITIAL_PARAMS,
					imaConfig: IMA_CONFIG,
					error,
				},
			},
			1,
		);
		if (on_error && typeof on_error === 'function') {
			on_error(error);
		}
	});

	/*
		On mobile devices, you must call initializeAdDisplayContainer as the result
		of a user action (e.g. button click). If you do not make this call, the SDK
		will make it for you, but not as the result of a user action. For more info
		see our examples, all of which are set up to work on mobile devices.
		*/
	// player.ima.initializeAdDisplayContainer()

	/* Workaround to prevent Google Chrome livestream black screen */
	player.on('readyforpreroll', () => {
		player.one('adend', () => {
			player.play();
		});
	});
}

/* Import video quality levels plugin. */
import 'videojs-contrib-quality-levels'
/* Import quality selector plugin. */
import videojsqualityselector from 'videojs-hls-quality-selector'

/* Import social sharing plugin. */
import 'videojs-share'

function generate_share_url(info) {
    let sanitizedTitle = info.title.toLowerCase();
    sanitizedTitle = sanitizedTitle.replace(/[\s_]+/g, '-');
    sanitizedTitle = sanitizedTitle.replace(/[^a-z0-9-]/g, '');
    sanitizedTitle = sanitizedTitle.replace(/^-+|-+$/g, '');
    sanitizedTitle = sanitizedTitle.replace(/-+/g, '-');
    return `https://${window.location.hostname}/video/${sanitizedTitle}/${info.id}`;
}

export function initialize_share(player, info) {
    /* Initialize sharing plugin. Currently contains dummy values. */
    const share_options = {
        socials: ['messenger', 'whatsapp', 'tw', 'email'],

        url: generate_share_url(info),
        title: info.title,
        image: 'https://dummyimage.com/1200x630',

        // required for Facebook and Messenger
        fbAppId: '12345',
        // optional for Facebook
        redirectUri: `${window.location.href}#close`,
    }

    if (!player.shareEmbed) {
        player.share(share_options);
        // return;
    }

		console.log('** hide second embed');
    // fetch(player.shareEmbed)
    //   .then((response) => response.json())
    //   .then((response) => {
    //     if (response.embed) {
    //         share_options.embedCode = response?.embed.replace(/"/g, '&quot;');
    //     }
    //   }).finally(() => {
    //     player.share(share_options);
    //   });
}

export function update_share(player, info) {
    const shareOverlay = player.getChild('ShareOverlay');
    if (shareOverlay) {
        shareOverlay.options.url = generate_share_url(info)
    }
}

export function initialize_quality(player) {
    /* Initialize quality selector plugin. */
    player.hlsQualitySelector = videojsqualityselector
    player.hlsQualitySelector({
        vjsIconClass: 'vjs-icon-cog',
    })
}

export default function initialize_ui(player, info) {
    initialize_share(player, info)
    initialize_quality(player)
}
